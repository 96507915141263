import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import MapComponent from '../map/MapComponent';
import ShareEvent from './ShareEvent';
const SingleEventDetails = ({ event }) => {
  const {
    DateTime,
    Name,
    image_url,
    location,
    Description,
    EndTime,
    EntryRequirement
  } = event;
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const eventStartDay = useMemo(
    () => format(new Date(DateTime), 'dd MMM, yyyy hh:mm a'),
    [DateTime]
  );

  const eventEndDay = useMemo(
    () => format(new Date(EndTime), 'dd MMM, yyyy hh:mm a'),
    [EndTime]
  );

  return (
    <div className="flex flex-col w-full max-w-3xl gap-8 mx-auto">
      <div className="relative">
        <img className="w-full h-[300px] object-cover" src={image_url} alt="" />
        <div className="absolute bottom-4 right-4">
          <ShareEvent urlToShare={window.location.href} />
        </div>
      </div>
      <div className="flex flex-col gap-1 text-gray-600 ">
        <h2 className="text-2xl font-medium text-gray-800">Event</h2>
        <p className="text-sm font-light">{Name}</p>
      </div>

      <div className="flex flex-col gap-1 text-gray-600 ">
        <h2 className="text-2xl font-medium text-gray-800">Description</h2>
        <p
          className={`text-sm font-light ${!showFullText ? 'line-clamp-3' : ''}`}
        >
          {/* Render text with line breaks */}
          {Description.split('\n').map((paragraph, index) => (
            <React.Fragment key={index}>
              {paragraph}
              <br />
            </React.Fragment>
          ))}
        </p>
        <button
          onClick={toggleShowFullText}
          className="mr-auto text-sm text-blue-500 "
        >
          {showFullText ? 'See Less' : 'See More'}
        </button>
      </div>
      {EntryRequirement && (
        <div className="flex flex-col gap-1 text-gray-600 ">
          <h2 className="text-2xl font-medium text-gray-800">
            Entry Requirement
          </h2>
          <p className="text-sm font-light">{EntryRequirement}</p>
        </div>
      )}
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col gap-1 text-gray-600 ">
          <h2 className="text-2xl font-medium text-gray-800">Starts At</h2>
          <p className="text-sm font-light">{eventStartDay}</p>
        </div>
        <div className="flex flex-col gap-1 text-gray-600 ">
          <h2 className="text-2xl font-medium text-gray-800">Ends At</h2>
          <p className="text-sm font-light">{eventEndDay}</p>
        </div>
        <div className="flex flex-col gap-1 text-gray-600 ">
          <h2 className="text-2xl font-medium text-gray-800">Location</h2>
          <p className="text-sm font-light">
            {location?.Name} , {location?.Address}
          </p>
        </div>
        <div className="flex flex-col gap-1 text-gray-600 ">
          <h2 className="text-2xl font-medium text-gray-800">Capacity</h2>
          <p className="text-sm font-light">{location?.Capacity}</p>
        </div>
        <div className="flex flex-col w-full col-span-2 gap-1 text-gray-600 ">
          <h2 className="text-2xl font-medium text-gray-800">Direction</h2>
          <MapComponent
            lat={parseFloat(location?.latitude) ?? 9.9312}
            lng={parseFloat(location?.longitude) ?? 76.2673}
          />
          <button
            onClick={() => window.open(location.direction_url, '_blank')}
            className="text-left text-blue-500 underline underline-offset-2"
          >
            Get Direction
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleEventDetails;
